<template>
  <b-form @submit.prevent="$emit('processUbicacion', ubicacion)">
    <!-- Ubicaciones Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Nombre -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nombre"
            label-for="nombre"
          >
            <b-form-input
              id="nombre"
              v-model="ubicacion.nombre"
              :state="!$v.ubicacion.nombre.$invalid"
              placeholder="Introduce el nombre"
              @input="$v.ubicacion.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.ubicacion.$dirty"
              id="nombreInfo"
            >
              El campo nombre
              <span v-if="!$v.ubicacion.nombre.required"> es requerido.</span>
              <span v-if="!$v.ubicacion.nombre.maxLength"> debe tener max 150 caracteres.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Field: Estado -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Estado"
            label-for="estado"
          >
            <b-form-select
              v-model="ubicacion.estado"
              placeholder="Selecciona un estado"
              :state="!$v.ubicacion.estado.$invalid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="estadoOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="estado"
              @input="$v.ubicacion.$touch"
            />
            <b-form-invalid-feedback
              v-if="$v.ubicacion.$dirty"
              id="estadoInfo"
            >
              El campo estado
              <span v-if="!$v.ubicacion.estado.required"> es requerido.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <btnSubmit
      :btnDisabled="$v.ubicacion.$invalid"
      :btnText="ubicacionSubmit"
    />
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect,
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BFormInvalidFeedback,
    // vSelect,

    btnSubmit,
  },
  mixins: [validationMixin],
  props: {
    ubicacion: {
      type: Object,
      required: true,
    },
    ubicacionSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Ubicación',
    },
  },
  validations: {
    ubicacion: {
      nombre: {
        required,
        maxLength: maxLength(150),
      },
      estado: {
        required,
      },
    },
  },
  setup() {
    const estadoOptions = [
      { value: null, text: 'Selecciona un estado' },
      { value: 'Activo', text: 'Activo' },
      { value: 'Inactivo', text: 'Inactivo' },
    ]

    return {
      estadoOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
